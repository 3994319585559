$navbarWidth: 240px;
$padding: 20px;
$paddingForDesktop: 40px;

@mixin widths($width) {
    max-width: $width;
    min-width: $width;
    width: $width;
}

.table-container {
    overflow: hidden;
    width: calc(100vw - $paddingForDesktop);
    margin: 0 auto 20px;

    @include rwd(desktop) {
        padding: 0 20px;
    }

    @include rwd(laptop) {
        width: calc(100vw - $navbarWidth - $paddingForDesktop);
    }

    .mat-mdc-form-field {
        width: 100%;
        padding: 0 5px;

        .mat-mdc-text-field-wrapper,
        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            background: none;
            padding: 0;

            .mat-mdc-form-field-focus-overlay {
                background: none;
            }
        }
    }

    .table-wrapper {
        background-color: $backgroundColorAlternate;
    }

    .table-scroll {
        background:
            linear-gradient(to right, $backgroundColorAlternate 30%, rgba($grayColor, 0)) center left,
            linear-gradient(to right, rgba($grayColor, 0), $backgroundColorAlternate 70%) center right,
            radial-gradient(farthest-corner at 0 50%, rgba($grayColor, 0.5), rgba($grayColor, 0)) center left,
            radial-gradient(farthest-corner at 100% 50%, rgba($grayColor, 0.5), rgba($grayColor, 0)) center right;
        background-repeat: no-repeat;
        background-position:
            left center,
            right center,
            left center,
            right center;
        background-size:
            40px 100%,
            40px 100%,
            14px 100%,
            14px 100%;
        background-attachment: local, local, scroll, scroll;
        overflow-x: auto;
        overflow-y: hidden;

        table {
            width: 100%;
            background-color: transparent;

            thead {
                th {
                    &.icon-header {
                        text-align: center;

                        .mat-sort-header-container {
                            justify-content: center;
                        }
                    }

                    .mat-sort-header-content {
                        text-align: left;
                    }
                }

                tr {
                    &.mdc-data-table__header-row {
                        background-color: $backgroundColor;

                        th.mdc-data-table__header-cell {
                            background-color: $backgroundColor;
                        }
                    }
                }
            }

            tbody {
                tr {
                    &.mdc-data-table__row {
                        background-color: $backgroundColorTransparent33;

                        &:nth-of-type(even) {
                            background-color: $backgroundColorSolid;
                        }

                        td.mat-column-expandedDetail {
                            background-color: transparent;

                            &.mdc-data-table__cell {
                                padding: 0;
                            }
                        }

                        td.mdc-data-table__cell {
                            padding: 5px 10px;
                        }

                        &.detail-row {
                            height: 0;
                        }

                        td {
                            word-wrap: break-word;
                        }
                    }

                    &.mat-mdc-no-data-row {
                        background-color: transparent;
                        line-height: 40px;
                    }
                }
            }
        }
    }
}
