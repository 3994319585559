@use '@angular/material' as mat;

@include mat.core();

@import 'styles/import';
@import 'styles/theme';
@import 'styles/components/import';

@mixin headerStyles($size) {
    text-align: center;
    letter-spacing: 1px;
    font-size: $size;
    margin-bottom: $size;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    font-size: $fontSizeM;

    @include rwd(laptop) {
        font-size: $fontSizeL;
    }
}

body {
    background-color: $backgroundColor;
    color: $fontColor;
    font-family: $robotoFontFamily;
    min-height: 100vh;
    min-width: 320px;
    position: relative;

    h1 {
        @include headerStyles($fontSizeXXL);
    }

    h2 {
        @include headerStyles($fontSizeXL);
    }

    h3 {
        @include headerStyles($fontSizeL);
    }

    p {
        text-align: justify;
        margin-bottom: 1rem;
    }

    a {
        color: $UPRedColor;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: $UPRedColorAccent;
            text-decoration: underline;
        }
    }

    .hidden {
        display: none !important;
    }

    .clear {
        clear: both;
    }

    input:-webkit-autofill {
        background-clip: text;
        -webkit-text-fill-color: $fontColor;
    }

    .grecaptcha-badge {
        visibility: hidden;
    }
}
