@mixin section {
    padding: 30px 0;

    @include rwd(tablet) {
        padding: 35px 0;
    }

    @include rwd(laptop) {
        padding: 80px 0;
    }
}

.content-wrapper {
    max-width: 1400px;
    margin: 0 auto;
}

.normal-section {
    @include section;
    background-color: $backgroundColor;
}

.alternate-section {
    @include section;
    background-color: $backgroundColorAlternate;
}
