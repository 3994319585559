@use 'sass:map';

$grayColors: (
    0: hsl(0, 0%, 0%),
    5: hsl(0, 0%, 5%),
    10: hsl(0, 0%, 10%),
    20: hsl(0, 0%, 20%),
    30: hsl(0, 0%, 30%),
    40: hsl(0, 0%, 40%),
    50: hsl(0, 0%, 50%),
    60: hsl(0, 0%, 60%),
    70: hsl(0, 0%, 70%),
    80: hsl(0, 0%, 80%),
    90: hsl(0, 0%, 90%),
    95: hsl(0, 0%, 95%),
    100: hsl(0, 0%, 100%)
);

$blackColor: map.get($grayColors, 0);
$grayColor: map.get($grayColors, 50);
$whiteColor: map.get($grayColors, 100);

$colorSaturation: 60%;

$blueColorHue: 225;
$blueColors: (
    50: hsl($blueColorHue, $colorSaturation, 95%),
    100: hsl($blueColorHue, $colorSaturation, 90%),
    150: hsl($blueColorHue, $colorSaturation, 85%),
    200: hsl($blueColorHue, $colorSaturation, 80%),
    250: hsl($blueColorHue, $colorSaturation, 85%),
    300: hsl($blueColorHue, $colorSaturation, 70%),
    350: hsl($blueColorHue, $colorSaturation, 65%),
    400: hsl($blueColorHue, $colorSaturation, 60%),
    450: hsl($blueColorHue, $colorSaturation, 55%),
    500: hsl($blueColorHue, $colorSaturation, 50%),
    550: hsl($blueColorHue, $colorSaturation, 45%),
    600: hsl($blueColorHue, $colorSaturation, 40%),
    650: hsl($blueColorHue, $colorSaturation, 35%),
    700: hsl($blueColorHue, $colorSaturation, 30%),
    750: hsl($blueColorHue, $colorSaturation, 25%),
    800: hsl($blueColorHue, $colorSaturation, 20%),
    850: hsl($blueColorHue, $colorSaturation, 15%),
    900: hsl($blueColorHue, $colorSaturation, 10%),
    950: hsl($blueColorHue, $colorSaturation, 5%),
    contrast: (
        50: $blackColor,
        100: $blackColor,
        200: $blackColor,
        300: $blackColor,
        400: $whiteColor,
        500: $whiteColor,
        600: $whiteColor,
        700: $whiteColor,
        800: $whiteColor,
        900: $whiteColor
    )
);

$redColorHue: 345;
$redColors: (
    50: hsl($redColorHue, $colorSaturation, 95%),
    100: hsl($redColorHue, $colorSaturation, 90%),
    200: hsl($redColorHue, $colorSaturation, 80%),
    300: hsl($redColorHue, $colorSaturation, 70%),
    400: hsl($redColorHue, $colorSaturation, 60%),
    500: hsl($redColorHue, $colorSaturation, 50%),
    600: hsl($redColorHue, $colorSaturation, 40%),
    700: hsl($redColorHue, $colorSaturation, 30%),
    800: hsl($redColorHue, $colorSaturation, 20%),
    900: hsl($redColorHue, $colorSaturation, 10%),
    contrast: (
        50: $blackColor,
        100: $blackColor,
        200: $blackColor,
        300: $blackColor,
        400: $whiteColor,
        500: $whiteColor,
        600: $whiteColor,
        700: $whiteColor,
        800: $whiteColor,
        900: $whiteColor
    )
);

$yellowColorHue: 45;
$yellowColorSaturation: 90%;
$yellowColors: (
    50: hsl($yellowColorHue, $yellowColorSaturation, 95%),
    100: hsl($yellowColorHue, $yellowColorSaturation, 90%),
    200: hsl($yellowColorHue, $yellowColorSaturation, 80%),
    300: hsl($yellowColorHue, $yellowColorSaturation, 70%),
    400: hsl($yellowColorHue, $yellowColorSaturation, 60%),
    500: hsl($yellowColorHue, $yellowColorSaturation, 50%),
    600: hsl($yellowColorHue, $yellowColorSaturation, 40%),
    700: hsl($yellowColorHue, $yellowColorSaturation, 30%),
    800: hsl($yellowColorHue, $yellowColorSaturation, 20%),
    900: hsl($yellowColorHue, $yellowColorSaturation, 10%),
    contrast: (
        50: $blackColor,
        100: $blackColor,
        200: $blackColor,
        300: $blackColor,
        400: $blackColor,
        500: $whiteColor,
        600: $whiteColor,
        700: $whiteColor,
        800: $whiteColor,
        900: $whiteColor
    )
);

$greenColorHue: 135;
$greenColors: (
    50: hsl($greenColorHue, $colorSaturation, 95%),
    100: hsl($greenColorHue, $colorSaturation, 90%),
    200: hsl($greenColorHue, $colorSaturation, 80%),
    300: hsl($greenColorHue, $colorSaturation, 70%),
    400: hsl($greenColorHue, $colorSaturation, 60%),
    500: hsl($greenColorHue, $colorSaturation, 50%),
    600: hsl($greenColorHue, $colorSaturation, 40%),
    700: hsl($greenColorHue, $colorSaturation, 30%),
    800: hsl($greenColorHue, $colorSaturation, 20%),
    900: hsl($greenColorHue, $colorSaturation, 10%),
    contrast: (
        50: $blackColor,
        100: $blackColor,
        200: $blackColor,
        300: $blackColor,
        400: $blackColor,
        500: $blackColor,
        600: $whiteColor,
        700: $whiteColor,
        800: $whiteColor,
        900: $whiteColor
    )
);

$backgroundColor: var(--background-color);
$backgroundColorAlternate: var(--background-color-alternate);
$backgroundColorSolid: var(--background-color-solid);
$backgroundColorTransparent33: var(--background-color-solid-transparent-33);
$backgroundColorTransparent66: var(--background-color-solid-transparent-66);
$backgroundColorGray: var(--background-color-gray);
$fontColor: var(--font-color);

$mainColor: var(--main-color);
$mainColorAccent: var(--main-color-accent);
$mainColorBackground: var(--main-color-background);

$errorColor: var(--error-color);
$errorColorAccent: var(--error-color-accent);
$errorColorBackground: var(--error-color-background);

$warningColor: var(--warning-color);
$warningColorAccent: var(--warning-color-accent);
$warningColorBackground: var(--warning-color-background);

$successColor: var(--success-color);
$successColorAccent: var(--success-color-accent);
$successColorBackground: var(--success-color-background);

$UPRedColor: #ff1b1c;
$UPRedColorAccent: #ff0009;
